import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from '@environment/environment.dev';
import { CountryData, Filter, Project, ProjectResult, SectorialDatum, SectorialInfo, TopFive } from '@interfaces/project.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService { 

  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;
  
  public filters = signal<Filter[]>([
    { id: 1, label: 'Country', expanded: false, options: [] },
    { id: 2, label: 'Sector', expanded: false, options: [] },
    { id: 3, label: 'Duration', expanded: false, options: [] },
    { id: 4, label: 'Status', expanded: false, options: [] },
    { id: 5, label: 'Instruments', expanded: false, options: [] },
    { id: 6, label: 'Executing-agency', expanded: false, options: [] },
    { id: 7, label: 'Donors', expanded: false, options: [] },
  ]);
  public filterSidebar = signal<boolean>(false);
  public clearFilter = signal<boolean>(false);
  public sectorialAlignment = signal<SectorialDatum[]>([]);
  public countriesData = signal<CountryData[]>([]);
  public institution: any;
  public dataSourceUrl = signal<any>('');

  private sectorsUrl = `${this._endpoint}ListSectors`;
  private instrumentsUrl = `${this._endpoint}ListInstruments`;
  private agenciesUrl = `${this._endpoint}ListAgencies`;
  private donorsUrl = `${this._endpoint}ListDonors`;

  public updateUrl(url: string) {
    this.dataSourceUrl.set(url);
  }

  public getDataSourceUrl() {
    return this.dataSourceUrl();
  }

  public getProjects() {
    return this._http.get<ProjectResult>(`${this._endpoint}GetProjects`);
  }

  public getProjectsFilter(filters: Filter[], currentPage: number) {
    let params = new HttpParams();

    if(filters.length > 0) {
      filters.forEach(filter => {
        if (filter.options.length > 0) {
          const paramValue = filter.options.join(',');
          switch (filter.label.toLowerCase()) {
            case 'country':
              params = params.append('country', paramValue);
              break;
            case 'sector':
              params = params.append('sector', paramValue);
              break;
            case 'duration':
              const dateParams = paramValue.split(',');
              params = params.append('start', dateParams[0]);
              params = params.append('end', dateParams[1]);
              params = params.append('start_end_date', dateParams[2]);
              params = params.append('end_end_date', dateParams[3]);              
              break;
            case 'status':
              params = params.append('status', paramValue);
              break;
            case 'instruments':
              params = params.append('instruments', paramValue);
              break;
            case 'executing-agency':
              params = params.append('agency', paramValue);
              break;
            case 'donors':
              params = params.append('donors', paramValue);
              break;
            default:
              break;
          }
        }
      });
    }

    params = params.append('page_number', currentPage - 1);
    params = params.append('page_size', 3);

    return this._http.get<ProjectResult>(`${this._endpoint}GetProjects`, { params });
  }

  public getProjectsDownload(year: string) {
    return this._http.get<ProjectResult>(`${this._endpoint}GetProjects?year=${year}&limit=50000`);
  }

  public updateFilter(filters: Filter[]) {
    this.filters.update(() => {
      return [...filters];
    });
  }

  public clearProjectsFilter(clear: boolean) {
    this.clearFilter.set(clear);
  }

  public updateCountriesData(countriesData: CountryData[]) {
    this.countriesData.update(() => {
      return [...countriesData];
    });
  }

  public updateSectorialAlignment(sectorialAlignment: SectorialDatum[]) {
    this.sectorialAlignment.update(() => {
      return [...sectorialAlignment];
    });
  }

  public updateSidebarFilterStatus() {
    this.filterSidebar.update(() => {
      return !this.filterSidebar();
    });
  }

  public setSidebarFilterStatus(status: boolean) { 
    this.filterSidebar.set(status);
  }

  public getSectorialAlignment(startYear: number, endYear: number) { 
    return this._http.get<SectorialDatum[]>(`${this._endpoint}GetYearSectorialData?startYear=${startYear}&endYear=${endYear}`);
  }

  public getSectorialInfo(startYear: number, endYear: number) { 
    return this._http.get<SectorialInfo>(`${this._endpoint}GetTopYearSectorialData?startYear=${startYear}&endYear=${endYear}`);
  }

  public getProject(id: string): Observable<Project[]> {
    return this._http.get<Project[]>(`${this._endpoint}GetProjects?unqu_id=${id}`);
  }

  public getProjectsCarrousel() {
    return this._http.get<Project[]>(`${this._endpoint}GetCarousel`);
  }

  public getTopFive() {
    return this._http.get<TopFive[]>(`${this._endpoint}GetTop5`);
  }

  public getSectors() {
    return this._http.get<string[]>(this.sectorsUrl);
  }

  public getInstruments() {
    return this._http.get<string[]>(this.instrumentsUrl);
  }

  public getAgencies() { 
    return this._http.get<string[]>(this.agenciesUrl);
  }

  public getCountries() {
    return this._http.get<CountryData[]>(`${this._endpoint}GetCountryData`);
  }

  public getInstitutionData(filterName: string): Observable<Project[]> {
    const endpointWithFilter = `${this._endpoint}GetProjects?recipient=${filterName}`;
    return this._http.get<Project[]>(endpointWithFilter);
  }

  public getDonors(){
    return this._http.get<string[]>(this.donorsUrl);
  }

}
